:root,
:host,
html,
body {
  /* changing and setting default styles */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --color:#ef5350;
}

.alert {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 103px;
  background: #eee;
  display: grid;
  place-content: center;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 1fr 30px;
  border-radius: 5px;
  border: 1px solid var(--color);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.alert-icon {
  display: grid;
  margin: auto;
  place-items: center;
  place-content: center;
  border: 2px solid var(--color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.alert-icon::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  top: 11.5px;
  left: 23.5px;
  margin: auto;
}
@-webkit-keyframes r {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes r {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.alert-message {
  width: 90%;
  max-width: 400px;
  margin: auto;
  border-left: 2px solid var(--color);
  padding-left: 1rem;
  font-size: 1rem;
  text-align: left;
  letter-spacing: 1px;
}
.alert-dismiss {
  color: #e57373;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  place-content: center;
  cursor: pointer;
  justify-self: right;
}
