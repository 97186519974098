.App {
  text-align: center;
  background-color: #fff;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}
/* Home Carousel Style start */
#root .react-multi-carousel-dot button {
  width: 12px;
  height: 12px;
  border-width: 1px;
}
#root .react-multi-carousel-dot--active button {
  background-color: #ccc;
}
#root .react-multiple-carousel__arrow--left {
  background: none;
  left: -6px;
}
#root .react-multiple-carousel__arrow::before {
  font-size: 45px;
  color: #333;
}
#root .react-multiple-carousel__arrow--right {
  right: 0px;
  background: none;
}
/* Home Carousel Style end */
.canvasCss {
  max-width: 100%;
}
.activeMenu {
  background-color: #ccc;
}

/*---------------------*/
svg.custom .node circle {
	fill: #F3F3FF;
	stroke: #2593B8;
	stroke-width: 1.5px;
}

svg.custom .node text {
	font-size: 11px;
	background-color: #444;
	fill: #333;
}

svg.custom .node {
	cursor: pointer;
}

svg.custom path.link {
	fill: none;
	stroke: #49879c;
	stroke-width: 1.5px;
}
/*---course compare general----*/
#courseCompareNotice {
  position: absolute;
  bottom: -42px;
  right: 150px;
  padding: 10px;
  color: #fff;
  background-image: linear-gradient(to right, green , #6bc787);
  display: none;
  cursor: pointer;
}
