/* FOR DASHBOARD NUMBER BOXES CHEVRON CSS DESKTOP VIEW*/
.dashBoardNumbers {
  margin: 14px;
  padding: 0;
  overflow: hidden;
}
.dashBoardNumbers li {
  float: left;
  margin-left: -24px;
  width: 204px; /* 100 / number of steps */
  height: 60px; /* total height */
  list-style-type: none;
  padding: 5px 5px 5px 30px; /* padding around text, last should include arrow width */
  border-right: 26px solid white; /* width: gap between arrows, color: background of document */
  position: relative;
  border-left: 7px solid white;
}
/* remove extra padding on the first object since it doesn't have an arrow to the left */
.dashBoardNumbers li:first-child {
  padding-left: 21px;
}
/* white arrow to the left to "erase" background (starting from the 2nd object) */
.dashBoardNumbers li:nth-child(n + 2)::before {
  position: absolute;
  top: -13px;
  left: 0;
  display: block;
  border-left: 25px solid white; /* width: arrow width, color: background of document */
  border-top: 46px solid transparent; /* width: half height */
  border-bottom: 34px solid transparent; /* width: half height */
  width: 0;
  height: 0;
  content: " ";
}
/* colored arrow to the right */
.dashBoardNumbers li::after {
  z-index: 1; /* need to bring this above the next item */
  position: absolute;
  top: -13px;
  right: -25px; /* arrow width (negated) */
  display: block;
  border-left: 25px solid #6bc787; /* width: arrow width */
  border-top: 46px solid transparent; /* width: half height */
  border-bottom: 34px solid transparent; /* width: half height */
  width: 0;
  height: 0;
  content: " ";
}

/* Setup colors (both the background and the arrow) */

/* Completed */
.dashBoardNumbers li {
  background-color: #6bc787;
}
.dashBoardNumbers li::after {
  border-left-color: #6bc787;
}

/* FOR DASHBOARD NUMBER BOXES CHEVRON CSS MOBILE VIEW*/
@media screen and (max-width: 1409px) {
  .dashBoardNumbers li {
    float: left;
    margin-left: -24px;
    width: 204px; /* 100 / number of steps */
    height: 60px; /* total height */
    list-style-type: none;
    padding: 5px 5px 5px 30px; /* padding around text, last should include arrow width */
    border-right: 26px solid white; /* width: gap between arrows, color: background of document */
    position: relative;
    border-left: 7px solid white;
    margin-bottom: 35px;
  }

  .dashBoardNumbers li:nth-child(n + 2)::before {
    position: absolute;
    top: -12px;
    left: 0;
    display: block;
    border-left: 28px solid white; /* width: arrow width, color: background of document */
    border-top: 44px solid transparent; /* width: half height */
    border-bottom: 37px solid transparent; /* width: half height */
    width: 0;
    height: 0;
    content: " ";
  }
  /* colored arrow to the right */
  .dashBoardNumbers li::after {
    z-index: 1; /* need to bring this above the next item */
    position: absolute;
    top: -1px;
    right: -19px; /* arrow width (negated) */
    display: block;
    border-left: 20px solid #6bc787; /* width: arrow width */
    border-top: 34px solid transparent; /* width: half height */
    border-bottom: 27px solid transparent; /* width: half height */
    width: 0;
    height: 0;
    content: " ";
  }
}
